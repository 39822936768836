<template>
  <div class="availability-container relative">
    <vx-card no-shadow>
      <connect-modal :show="timeRangeDialog" :width="'460px'" :height="'300px'" bkcolor="#F7F7F7">
        <div class="flex flex-row visitor-list-delete-all">
          <div>
            <h3>{{ $t('inputs.selectTimeRange') }}</h3>
          </div>
          <div disabled style="margin-left: auto; cursor: pointer" @click="timeRangeDialog = false">
            <close-circle-icon class="pa-bk-secondary" :show-circle="false" :width="14" :height="14"></close-circle-icon>
          </div>
        </div>
        <div class="flex flex-wrap justify-center" style="height: 200px">
          <!-- 12-hour format, with AM/PM picker -->
          <vs-row w="12">
            <vs-col w="12">
              <label class="text-sm">{{ $t('inputs.start') }}</label>
            </vs-col>
          </vs-row>

          <vue-timepicker
            close-on-complete
            class="w-full mb-base"
            format="HH:mm"
            :minute-interval="minuteInterval"
            v-model="start"
            @change="timeChange"
            fixed-dropdown-button
            drop-direction="down"
          />

          <vs-row w="12">
            <vs-col w="12">
              <label class="text-sm">{{ $t('inputs.end') }}</label>
            </vs-col>
          </vs-row>

          <vue-timepicker
            close-on-complete
            drop-direction="up"
            fixed-dropdown-button
            hide-disabled-items
            class="w-full mb-base"
            format="HH:mm"
            :hour-range="hourRange"
            :minute-range="minuteRange"
            :minute-interval="minuteInterval"
            v-model="end"
            @change="timeChange"
            @error="errorHanlder"
          />

          <vs-button class="w-full mb-base" color="primary" type="filled" icon="add" :disabled="hasError" @click.prevent="addUpdateTimeRange">
            {{ $t('vue.addTimeRange') }}
          </vs-button>
        </div>
      </connect-modal>

      <form ref="form">
        <div class="flex flex-wrap items-center">
          <vs-row v-for="(slot, d) in availableTimeSlots" :key="d">
            <div class="vx-row slot-wrapper">
              <div class="vx-col w-full">
                <vs-checkbox hide-details class="ma-0 pt-0 pb-1 checkbox-main" v-model="slot.enabled" @change="slotUpdated(slot, d)">
                  {{ $t(modifyDayNameToLong(slot.day)) }}
                </vs-checkbox>

                <div v-if="slot.enabled" class="slot-details">
                  <div
                    v-for="(time, t) in slot.times"
                    class="slot-item"
                    :class="{
                      'mb-0': !isCustomDays
                    }"
                    :key="t"
                  >
                    <div class="slot-item__time">{{ format12HrTime(time.start) }} - {{ format12HrTime(time.end) }}</div>

                    <div class="slot-item__actions">
                      <button class="flex border-none cursor-pointer" @click.prevent="editTime(d, t, time)">
                        <PenEditIcon :color="'#3B86F7'" />
                      </button>

                      <button v-if="isCustomDays" class="flex border-none cursor-pointer" @click.prevent="deleteTime(t, slot)">
                        <DeleteIcon :color="'#F05541'" />
                      </button>
                    </div>
                  </div>

                  <vs-button v-if="isCustomDays" size="medium" color="primary" class="btn-main btn-add" @click="addTime(d)">
                    <span class="btn-add__label">{{ $t('inputs.add') }}</span>
                  </vs-button>
                </div>
              </div>
            </div>
          </vs-row>
        </div>

        <div class="timezone-select">
          <multiselect
            label="fullTimezoneValue"
            track-by="timezone"
            :options="timezoneList"
            :multiple="false"
            :close-on-select="true"
            :clear-on-select="false"
            :preserve-search="true"
            :placeholder="'Select timezone'"
            :selectLabel="$t('vue.pressEnterToSelect')"
            :selectedLabel="$t('vue.selectedLabel')"
            :deselectLabel="$t('vue.pressEnterToRemove')"
            :preselect-first="false"
            v-model="selectedTimezone"
            @input="updateSelectedTimezone"
          />
        </div>
      </form>
    </vx-card>
  </div>
</template>

<script>
import moment from 'moment'
import moment_timezone from 'moment-timezone'
import _ from 'lodash'

import 'vue-multiselect/dist/vue-multiselect.min.css'
import Multiselect from 'vue-multiselect'

// Main JS (in UMD format)
import VueTimepicker from 'vue2-timepicker'
// CSS
import 'vue2-timepicker/dist/VueTimepicker.css'

import ConnectModal from '@/components/ConnectModal.vue'
import CloseCircleIcon from '@/components/icons/CloseCircleIcon.vue'
import PenEditIcon from '@/components/icons/PenEditIcon'
import DeleteIcon from '@/components/icons/DeleteIcon'

const dayjs = require('dayjs')
const utc = require('dayjs/plugin/utc') // dependent on utc plugin
const timezone = require('dayjs/plugin/timezone') // dependent on utc plugin
dayjs.extend(utc)
dayjs.extend(timezone)

export default {
  components: {
    Multiselect,
    VueTimepicker,
    PenEditIcon,
    DeleteIcon,
    ConnectModal,
    CloseCircleIcon
  },

  props: {
    settingsKey: {
      type: String,
      required: true
    },
    isCustomDays: {
      type: Boolean,
      required: true
    },
    dialog: {
      type: Object,
      required: true
    }
  },

  data() {
    return {
      countryCodes: require('@/assets/countryCodes.json'),
      timezoneList: [],
      selectedTimezone: null,
      defaultTimezone: 'Europe/Berlin',

      timeRangeDialog: false,
      timeRangeDialogType: null,
      start: {
        HH: '00',
        mm: '00'
      },
      end: {
        HH: '00',
        mm: '00'
      },
      minuteInterval: 10,
      selectedDay: null,
      selectedTime: null,
      availableTimeSlots: [
        {
          day: 'system.weekdays.short.mon',
          enabled: false,
          times: []
        },
        {
          day: 'system.weekdays.short.tue',
          enabled: false,
          times: []
        },
        {
          day: 'system.weekdays.short.wed',
          enabled: false,
          times: []
        },
        {
          day: 'system.weekdays.short.thu',
          enabled: false,
          times: []
        },
        {
          day: 'system.weekdays.short.fri',
          enabled: false,
          times: []
        },
        {
          day: 'system.weekdays.short.sat',
          enabled: false,
          times: []
        },
        {
          day: 'system.weekdays.short.sun',
          enabled: false,
          times: []
        }
      ],
      minuteRange: [[0, 60]],
      hasError: true,
      previousTimeslots: []
    }
  },

  computed: {
    hourRange() {
      const startHour = parseInt(this.start.HH)

      return startHour === 23 ? [23] : [[startHour, 23]]
    }
  },

  async mounted() {
    await this.$vs.loading()

    this.setDefaultTimeslots()
    this.getUserAvailability()
    this.setTimezoneList()
    this.setDefaultTimezone()
    await this.setDefaultTimeslots()

    await this.$vs.loading.close()
  },

  methods: {
    async setDefaultTimeslots() {
      if (!this.isCustomDays && !this.dialog.workingDaysSettings) {
        this.availableTimeSlots = this.availableTimeSlots.map((item) => {
          item.enabled = true
          item.times = [{ start: '09:00', end: '17:00' }]

          return item
        })

        const data = {
          [this.settingsKey]: {
            availableTimeSlots: this.availableTimeSlots
          }
        }

        await this.$db.collection('dialogs').doc(this.dialog.id).set(data, { merge: true })
      }
    },

    setTimezoneList() {
      const timezoneList = []

      this.countryCodes.forEach((country) => {
        const list = moment_timezone.tz.zonesForCountry(country.code)

        if (!list) {
          return
        }

        const countryInfo = {
          code: country.code,
          name: country.name,
          dialCode: country.dialCode
        }

        list.forEach((item) => {
          if (timezoneList.findIndex((timezoneItem) => timezoneItem.timezone === item) < 0) {
            const utcOffsetValue = dayjs().tz(item).utcOffset()
            const modifiedUtcOffsetValue = `UTC ${
              utcOffsetValue !== 0 ? (utcOffsetValue > 0 ? `+${utcOffsetValue / 60}` : utcOffsetValue / 60) : `+${utcOffsetValue}`
            }`
            const fullTimezoneValue = `${item} (${modifiedUtcOffsetValue})`

            timezoneList.push({ countryInfo, fullTimezoneValue, timezone: item })
          }
        })
      })

      timezoneList.sort((a, b) => {
        if (a.timezone < b.timezone) {
          return -1
        } else if (a.timezone > b.timezone) {
          return 1
        }

        return 0
      })

      this.timezoneList = timezoneList

      if (this.dialog[this.settingsKey] && this.dialog[this.settingsKey].timezoneInfo) {
        const selectedTimezone = this.timezoneList.find((item) => item.timezone === this.dialog[this.settingsKey].timezoneInfo.timezone)

        if (selectedTimezone) {
          this.selectedTimezone = selectedTimezone
        }
      }
    },

    setDefaultTimezone() {
      if (this.selectedTimezone || !this.timezoneList.length) {
        return
      }

      const defaultTimezone = this.timezoneList.find((item) => item.timezone === this.defaultTimezone)

      if (defaultTimezone) {
        this.selectedTimezone = defaultTimezone
      }
    },

    async updateSelectedTimezone() {
      await this.$vs.loading()

      const timezoneInfo = {
        countryInfo: this.selectedTimezone.countryInfo,
        timezone: this.selectedTimezone.timezone
      }

      const data = {
        [this.settingsKey]: {
          timezoneInfo
        }
      }

      await this.$db.collection('dialogs').doc(this.dialog.id).set(data, { merge: true })
      await this.$vs.loading.close()
    },

    getUserAvailability() {
      if (this.dialog[this.settingsKey] && this.dialog[this.settingsKey].availableTimeSlots && this.dialog[this.settingsKey].availableTimeSlots.length > 0) {
        this.availableTimeSlots = [...[]]
        this.previousTimeslots = [...[]]

        this.availableTimeSlots = _.cloneDeep([...this.dialog[this.settingsKey].availableTimeSlots])
        this.previousTimeslots = _.cloneDeep(this.availableTimeSlots)
      }
    },

    errorHanlder(e) {
      this.hasError = e.length > 0
    },

    timeChange() {
      const startHour = parseInt(this.start.HH)
      const startMinute = parseInt(this.start.mm)
      const endHour = parseInt(this.end.HH)

      let minuteRange = []

      if (startHour === endHour) {
        const endMinute = startMinute + this.minuteInterval

        minuteRange = endMinute > 60 - this.minuteInterval ? [] : [[endMinute, 60]]
      } else {
        minuteRange = [[0, 60]]
      }

      this.minuteRange = minuteRange
    },

    async addUpdateTimeRange() {
      await this.$vs.loading()

      if (this.timeRangeDialogType === 'system.add') {
        if (this.start !== null && this.end !== null) {
          this.availableTimeSlots[this.selectedDay].times.push({
            start: `${this.start.HH}:${this.start.mm}`,
            end: `${this.end.HH}:${this.end.mm}`
          })
        }
      }

      if (this.timeRangeDialogType === 'system.save') {
        if (this.start !== null && this.end !== null) {
          this.availableTimeSlots[this.selectedDay].times[this.selectedTime] = {
            start: `${this.start.HH}:${this.start.mm}`,
            end: `${this.end.HH}:${this.end.mm}`
          }
        }
      }

      this.start = {
        HH: '00',
        mm: '00'
      }

      this.end = {
        HH: '00',
        mm: '00'
      }

      this.selectedDay = null
      this.selectedTime = null
      this.timeRangeDialog = false

      const data = {
        [this.settingsKey]: {
          availableTimeSlots: this.availableTimeSlots
        }
      }

      await this.$db.collection('dialogs').doc(this.dialog.id).set(data, { merge: true })
      await this.$vs.loading.close()
    },

    async slotUpdated(slot) {
      await this.$vs.loading()

      if (!slot.enabled && this.isCustomDays) {
        slot.times = []
      }

      const data = {
        [this.settingsKey]: {
          availableTimeSlots: this.availableTimeSlots
        }
      }

      await this.$db.collection('dialogs').doc(this.dialog.id).set(data, { merge: true })
      await this.$vs.loading.close()
    },

    addTime(i) {
      this.selectedDay = i
      this.timeRangeDialog = true
      this.timeRangeDialogType = 'system.add'
    },

    editTime(d, t, time) {
      this.selectedDay = d
      this.selectedTime = t

      if (time.start && !time.start.HH && time.start.indexOf(':') > -1) {
        const start = {
          HH: time.start.split(':')[0],
          mm: time.start.split(':')[1]
        }

        this.start = start
      } else {
        this.start = time.start
      }

      if (time.end && !time.end.HH && time.end.indexOf(':') > -1) {
        const end = {
          HH: time.end.split(':')[0],
          mm: time.end.split(':')[1]
        }

        this.end = end
      } else {
        this.end = time.end
      }

      this.timeRangeDialog = true
      this.timeRangeDialogType = 'system.save'
    },

    async deleteTime(i, slot) {
      await this.$vs.loading()

      slot.times.splice(i, 1)

      const data = {
        [this.settingsKey]: {
          availableTimeSlots: this.availableTimeSlots
        }
      }

      await this.$db.collection('dialogs').doc(this.dialog.id).set(data, { merge: true })
      await this.$vs.loading.close()
    },

    format12HrTime(time24hr) {
      return moment(time24hr, 'HH:mm').locale(this.$i18n.locale).format('LT')
    },

    modifyDayNameToLong(value) {
      if (!value || !(typeof value === 'string' || value instanceof String)) {
        return value
      }

      return value.replace('short', 'long')
    }
  }
}
</script>

<style lang="scss">
.timezone-select {
  width: 322px;
  height: 35px;
  max-height: 35px;

  &--empty {
    .multiselect__input {
      padding-left: 40px;
    }
  }

  .multiselect {
    color: #000;
    z-index: 1000;

    &__select {
      z-index: 1;

      &::before {
        border-color: #000 transparent transparent;
      }
    }

    &__placeholder {
      padding-top: 0;
      margin-bottom: 0px;
    }

    &__tags {
      padding: 10px 5px 0 5px;
      background: #e6ecf1;

      &-wrap {
        padding-left: 40px;
      }
    }

    &__single {
      background: #e6ecf1;
      font-size: 1em;
    }

    &__input {
      background: #e6ecf1;
    }

    &__content-wrapper {
      box-shadow: rgba(0, 0, 0, 0.3) 0px 4px 25px 0px;
    }

    &--active {
      &__tags {
        padding: 10px 40px 0 8px;
      }
    }
  }

  &-select {
    padding: 10px;
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-radius: 5px;
    -webkit-box-shadow: none;
    box-shadow: none;
    font-family: "Lato", Helvetica, Arial, sans-serif;
    font-weight: 400;
    width: 100%;
    line-height: 1.5;
    letter-spacing: 0.01rem;
  }
}
</style>

<style lang="scss">
.pa-bk-secondary {
  color: #fff !important;
  background: rgba(var(--vs-secondary), 1) !important;
  border-radius: 10px;
}

.vue__time-picker {
  .dropdown {
    ul {
      li:not([disabled]) {
        &.active,
        &.active:focus,
        &.active:hover {
          color: rgb(255, 255, 255);
          background: rgba(var(--vs-primary), 1);
        }
      }
    }
  }
}

.vs-popup {
  width: 460px !important;
}

.vue__time-picker {
  input.display-time {
    width: 100%;
  }
}

.btn {
  &-main {
    font-size: 20px;
  }

  &-add {
    &__label {
      position: relative;
      margin-left: 35px;
      margin-right: 35px;

      &::before,
      &::after {
        content: '';
        display: inline-block;
        position: absolute;
        top: 50%;
        height: 1.5px;
        background: rgb(255, 255, 255);
      }

      &::before {
        left: -22px;
        width: 13px;
      }

      &::after {
        left: -21px;
        width: 12px;
        transform: rotate(90deg);
      }
    }
  }
}

.checkbox-main {
  font-size: 18px;

  .vs-checkbox {
    margin-right: 20px;
    width: 22px;
    height: 22px;
  }
}

.slot {
  &-wrapper {
    margin-bottom: 30px;
  }

  &-details {
    @media screen and (min-width: 768px) {
      margin-left: 48px;
    }
  }

  &-item {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    padding: 10px 20px;
    width: 275px;
    font-size: 18px;
    background: rgba(230, 236, 241, 0.5);
    border-radius: 6px;

    &__time {
      flex: 1;
    }

    &__actions {
      display: flex;

      & > * {
        margin-left: 10px;

        &:first-child {
          margin-left: 0;
        }
      }
    }
  }
}
</style>
