var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "availability-container relative" },
    [
      _c(
        "vx-card",
        { attrs: { "no-shadow": "" } },
        [
          _c(
            "connect-modal",
            {
              attrs: {
                show: _vm.timeRangeDialog,
                width: "460px",
                height: "300px",
                bkcolor: "#F7F7F7",
              },
            },
            [
              _c(
                "div",
                { staticClass: "flex flex-row visitor-list-delete-all" },
                [
                  _c("div", [
                    _c("h3", [
                      _vm._v(_vm._s(_vm.$t("inputs.selectTimeRange"))),
                    ]),
                  ]),
                  _c(
                    "div",
                    {
                      staticStyle: { "margin-left": "auto", cursor: "pointer" },
                      attrs: { disabled: "" },
                      on: {
                        click: function ($event) {
                          _vm.timeRangeDialog = false
                        },
                      },
                    },
                    [
                      _c("close-circle-icon", {
                        staticClass: "pa-bk-secondary",
                        attrs: { "show-circle": false, width: 14, height: 14 },
                      }),
                    ],
                    1
                  ),
                ]
              ),
              _c(
                "div",
                {
                  staticClass: "flex flex-wrap justify-center",
                  staticStyle: { height: "200px" },
                },
                [
                  _c(
                    "vs-row",
                    { attrs: { w: "12" } },
                    [
                      _c("vs-col", { attrs: { w: "12" } }, [
                        _c("label", { staticClass: "text-sm" }, [
                          _vm._v(_vm._s(_vm.$t("inputs.start"))),
                        ]),
                      ]),
                    ],
                    1
                  ),
                  _c("vue-timepicker", {
                    staticClass: "w-full mb-base",
                    attrs: {
                      "close-on-complete": "",
                      format: "HH:mm",
                      "minute-interval": _vm.minuteInterval,
                      "fixed-dropdown-button": "",
                      "drop-direction": "down",
                    },
                    on: { change: _vm.timeChange },
                    model: {
                      value: _vm.start,
                      callback: function ($$v) {
                        _vm.start = $$v
                      },
                      expression: "start",
                    },
                  }),
                  _c(
                    "vs-row",
                    { attrs: { w: "12" } },
                    [
                      _c("vs-col", { attrs: { w: "12" } }, [
                        _c("label", { staticClass: "text-sm" }, [
                          _vm._v(_vm._s(_vm.$t("inputs.end"))),
                        ]),
                      ]),
                    ],
                    1
                  ),
                  _c("vue-timepicker", {
                    staticClass: "w-full mb-base",
                    attrs: {
                      "close-on-complete": "",
                      "drop-direction": "up",
                      "fixed-dropdown-button": "",
                      "hide-disabled-items": "",
                      format: "HH:mm",
                      "hour-range": _vm.hourRange,
                      "minute-range": _vm.minuteRange,
                      "minute-interval": _vm.minuteInterval,
                    },
                    on: { change: _vm.timeChange, error: _vm.errorHanlder },
                    model: {
                      value: _vm.end,
                      callback: function ($$v) {
                        _vm.end = $$v
                      },
                      expression: "end",
                    },
                  }),
                  _c(
                    "vs-button",
                    {
                      staticClass: "w-full mb-base",
                      attrs: {
                        color: "primary",
                        type: "filled",
                        icon: "add",
                        disabled: _vm.hasError,
                      },
                      on: {
                        click: function ($event) {
                          $event.preventDefault()
                          return _vm.addUpdateTimeRange.apply(null, arguments)
                        },
                      },
                    },
                    [
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.$t("vue.addTimeRange")) +
                          "\n        "
                      ),
                    ]
                  ),
                ],
                1
              ),
            ]
          ),
          _c("form", { ref: "form" }, [
            _c(
              "div",
              { staticClass: "flex flex-wrap items-center" },
              _vm._l(_vm.availableTimeSlots, function (slot, d) {
                return _c("vs-row", { key: d }, [
                  _c("div", { staticClass: "vx-row slot-wrapper" }, [
                    _c(
                      "div",
                      { staticClass: "vx-col w-full" },
                      [
                        _c(
                          "vs-checkbox",
                          {
                            staticClass: "ma-0 pt-0 pb-1 checkbox-main",
                            attrs: { "hide-details": "" },
                            on: {
                              change: function ($event) {
                                return _vm.slotUpdated(slot, d)
                              },
                            },
                            model: {
                              value: slot.enabled,
                              callback: function ($$v) {
                                _vm.$set(slot, "enabled", $$v)
                              },
                              expression: "slot.enabled",
                            },
                          },
                          [
                            _vm._v(
                              "\n                " +
                                _vm._s(
                                  _vm.$t(_vm.modifyDayNameToLong(slot.day))
                                ) +
                                "\n              "
                            ),
                          ]
                        ),
                        slot.enabled
                          ? _c(
                              "div",
                              { staticClass: "slot-details" },
                              [
                                _vm._l(slot.times, function (time, t) {
                                  return _c(
                                    "div",
                                    {
                                      key: t,
                                      staticClass: "slot-item",
                                      class: {
                                        "mb-0": !_vm.isCustomDays,
                                      },
                                    },
                                    [
                                      _c(
                                        "div",
                                        { staticClass: "slot-item__time" },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              _vm.format12HrTime(time.start)
                                            ) +
                                              " - " +
                                              _vm._s(
                                                _vm.format12HrTime(time.end)
                                              )
                                          ),
                                        ]
                                      ),
                                      _c(
                                        "div",
                                        { staticClass: "slot-item__actions" },
                                        [
                                          _c(
                                            "button",
                                            {
                                              staticClass:
                                                "flex border-none cursor-pointer",
                                              on: {
                                                click: function ($event) {
                                                  $event.preventDefault()
                                                  return _vm.editTime(
                                                    d,
                                                    t,
                                                    time
                                                  )
                                                },
                                              },
                                            },
                                            [
                                              _c("PenEditIcon", {
                                                attrs: { color: "#3B86F7" },
                                              }),
                                            ],
                                            1
                                          ),
                                          _vm.isCustomDays
                                            ? _c(
                                                "button",
                                                {
                                                  staticClass:
                                                    "flex border-none cursor-pointer",
                                                  on: {
                                                    click: function ($event) {
                                                      $event.preventDefault()
                                                      return _vm.deleteTime(
                                                        t,
                                                        slot
                                                      )
                                                    },
                                                  },
                                                },
                                                [
                                                  _c("DeleteIcon", {
                                                    attrs: { color: "#F05541" },
                                                  }),
                                                ],
                                                1
                                              )
                                            : _vm._e(),
                                        ]
                                      ),
                                    ]
                                  )
                                }),
                                _vm.isCustomDays
                                  ? _c(
                                      "vs-button",
                                      {
                                        staticClass: "btn-main btn-add",
                                        attrs: {
                                          size: "medium",
                                          color: "primary",
                                        },
                                        on: {
                                          click: function ($event) {
                                            return _vm.addTime(d)
                                          },
                                        },
                                      },
                                      [
                                        _c(
                                          "span",
                                          { staticClass: "btn-add__label" },
                                          [_vm._v(_vm._s(_vm.$t("inputs.add")))]
                                        ),
                                      ]
                                    )
                                  : _vm._e(),
                              ],
                              2
                            )
                          : _vm._e(),
                      ],
                      1
                    ),
                  ]),
                ])
              }),
              1
            ),
            _c(
              "div",
              { staticClass: "timezone-select" },
              [
                _c("multiselect", {
                  attrs: {
                    label: "fullTimezoneValue",
                    "track-by": "timezone",
                    options: _vm.timezoneList,
                    multiple: false,
                    "close-on-select": true,
                    "clear-on-select": false,
                    "preserve-search": true,
                    placeholder: "Select timezone",
                    selectLabel: _vm.$t("vue.pressEnterToSelect"),
                    selectedLabel: _vm.$t("vue.selectedLabel"),
                    deselectLabel: _vm.$t("vue.pressEnterToRemove"),
                    "preselect-first": false,
                  },
                  on: { input: _vm.updateSelectedTimezone },
                  model: {
                    value: _vm.selectedTimezone,
                    callback: function ($$v) {
                      _vm.selectedTimezone = $$v
                    },
                    expression: "selectedTimezone",
                  },
                }),
              ],
              1
            ),
          ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }